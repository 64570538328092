@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

* {
  font-family: "Montserrat", sans-serif !important;
}
html , body{
    overflow-x: hidden;
}
.about-intro {
  background-color: #51CDC4;
  background-image: linear-gradient(315deg, #51CDC4, #a8e6e1 74%);
  overflow-x: hidden !important;
  transform: rotate(12deg);
  position: absolute;
  top: -30%;
  left: -100px;
  right: 0;
  border-radius: 50px
}

@tailwind base;
@tailwind components;
@tailwind utilities;
